<template>
  <div>
    <v-container fluid>
      <v-card
        height="500"
        color="transparent"
        flat
      >
        <v-card-title class="text-h2">
          Erreur 500
        </v-card-title>
        <v-card-subtitle class="text-h6">
          Erreur serveur.
        </v-card-subtitle>
        <v-card-text>
          Une erreur inconnue serveur est survenue. Ceci est très probablement un bug. Merci de le signaler !
        </v-card-text>
        <v-btn
          outlined
          to="/"
          class="mt-1"
        >
          Retour Accueil
        </v-btn>
      </v-card>
    </v-container>
  </div>
</template>
<script>
export default {
  name: "Error500",
};
</script>